var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9871606573e41d4d511fdb9095ccf431b8fb70ad"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

const sentryDsn = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const sentryTracesSampleRate = process.env.SENTRY_TRACES_SAMPLE_RATE || process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE;

if (sentryDsn) {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_APP_ENV,
    dsn: sentryDsn,
    release: process.env.NEXT_PUBLIC_APP_NAME_VERSION,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        idleTimeout: 10000,
      }),
    ],
    tracesSampleRate: sentryTracesSampleRate,
    ignoreErrors: ['Network Error'],
  });
  Sentry.setTags({
    build_timestamp: process.env.NEXT_PUBLIC_APP_BUILD_TIMESTAMP,
    build_version: process.env.NEXT_PUBLIC_APP_VERSION,
  });
  Sentry.setContext('build info', {
    commit: process.env.GIT_COMMIT_SHA_SHORT,
    time: process.env.NEXT_PUBLIC_APP_BUILD_TIME,
    name: process.env.NEXT_PUBLIC_APP_NAME_VERSION,
  });
}
